export default {
	// api请求地址
	baseUrl: 'https://admin.hejiaju.com',
	//baseUrl: 'http://admin.hejiaju.com.local',
	// 图片域名
	imgDomain: 'https://shop-1308600962.cos.ap-shanghai.myqcloud.com',
	// 前端域名
	webDomain: 'https://www.hejiaju.com',
	// 腾讯地图key
	mpKey: '',
	// 客服
	webSocket: '',
	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500
}