<template>
    <div class="footer">
        <div style="background:#f2f2f2">
			<img style="margin:80px auto 0;display:block;width:1340px;height:160px;" src="@/assets/images/index/service.png" alt="" />
		</div>
        <div style="background-color:#333;color:#aaa;padding:40px 0;">
            <div style="width:1340px;margin:0 auto;display:flex;justify-content: space-between;padding-bottom:40px;">
                <div style="padding-top:40px;width:320px;">
                    <img style="width:200px;height:86px;" src="@/assets/images/index/logo.png" alt="">
                </div>
                <div style="width:150px;line-height:2" v-for="(item,index) in helpList" :key="index">
                    <div style="font-size:18px;color:#ccc;border-bottom:1px solid #444;margin-bottom:4px;">
                        <router-link style="color:#ccc" :to="'/cms/help/listother-'+item.class_id">{{item.class_name}}</router-link>
                    </div>
                    <div v-for="li in item.child_list" :key="li.title">
                        <router-link :to="li.link_address || ('/cms/help-'+li.id)">{{li.title}}</router-link>
                    </div>
                </div>
                <div style="text-align:center">
                    <img style="opacity:0.8" :src="$img('upload/qrcode/shop/shop_qrcode_1_h5.png')" alt="">
                    <div style="padding-top:10px;">手机商城</div>
                </div>
            </div>
            <div style="text-align:center;color:#aaa;width:1340px;border-top:1px solid #444;margin:0 auto;padding-top:40px;line-height:32px">
                © 2015 - {{year}} 和家居 成都和家美居信息技术有限公司 保留所有权利。<br />
                <a style="color:#aaa" href="https://beian.miit.gov.cn/" ref="nofollow" target="_blank">蜀ICP备2024067664号</a>
            </div>
        </div>
    </div>
</template>

<script>
	import {
		helpList
	} from "@/api/cms/help"

	export default {
		name:'nsfooter',
		data() {
			return {
                year:(new Date).getFullYear(),
				helpList: [],
			}
		},
		created() {
			this.getHelpList()
		},
		methods: {
		    
			/**
			 * 获取帮助列表
			 */
			getHelpList() {
				helpList()
					.then(res => {
						if (res.code == 0 && res.data) {
							var arr = [];
							arr = res.data.slice(0, 4)
							for (let i = 0; i < arr.length; i++) {
								arr[i].child_list = arr[i].child_list.slice(0, 4);
							}
							this.helpList = arr
						}
					})
					.catch(err => {})
            },

		},
		components: {
			
		}
	}
</script>

<style scoped lang="scss">
.footer a{
    color:#aaa
}
</style>

