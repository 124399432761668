<template>
	<div class="header-in">
		<el-row>
			<el-col :span="6">
				<router-link to="/" class="logo-wrap">
					<img src="@/assets/images/index/logo.png" />
				</router-link>
			</el-col>
			<el-col :span="12">
				<div class="in-sousuo">
					<div class="sousuo-box">
						<input type="text" placeholder="请输入搜索内容" v-model="keyword" @keyup.enter="search(false)" maxlength="50" />
						<el-button type="primary" size="small" @click="search(false)">搜&emsp;索</el-button>
					</div>
					<div class="hot-search-words" v-if="hotSearchWords.length">
						<ul>
							<li v-for="(item, index) in hotSearchWords" :key="index" @click="search(item)">
								{{ item }}
							</li>
						</ul>
					</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="cart-wrap">
					<router-link class="cart" to="/cart">
						<span>我的购物车</span>
						<el-badge v-if="cartCount" :value="cartCount" type="primary"><i class="iconfont icon-gouwuche"></i></el-badge>
						<i v-else class="iconfont icon-gouwuche"></i>
					</router-link>

					<div class="list">
						<template v-if="cartList.length">
							<h4>最新加入的商品</h4>
							<div class="overflow-wrap">
								<ul :class="{ overflow: cartList.length > 5 }">
									<li class="item" v-for="(item, index) in cartList" :key="index">
										<div class="img-wrap"><img :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" :alt="item.sku_name" /></div>
										<div class="goods-name">{{ item.sku_name }}</div>
										<div class="operation">
											<p>￥{{ item.discount_price }}x{{ item.num }}</p>
											<span @click="deleteCart(index)">删除</span>
										</div>
									</li>
								</ul>
							</div>
							<div class="total">
								<span>
									共
									<strong>{{ cartList.length }}</strong>
									种商品，总金额
									<strong>{{ cartTotalPrice }}</strong>
									元
								</span>

								<el-button type="primary" size="mini" @click="$router.push('/cart')">去购物车</el-button>
							</div>
						</template>
						<div class="empty" v-else>
							<i class="iconfont icon-gouwuche"></i>
							<span>您的购物车是空的，赶快去逛逛，挑选商品吧！</span>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import {
		cartList as apiCartList
	} from '@/api/goods/cart';
	export default {
		props: {},
		data() {
			return {
				searchType: 'goods',
				searchTypeText: '商品',
				hotSearchWords:['沙发','茶几','电视柜','床','床头柜','餐桌','餐椅'],
				keyword: '',
				category_id:'',
				level:'',
				cartList: [],
				cartTotalPrice: 0
			};
		},
		components: {},
		computed: {
			...mapGetters(['cartCount', 'siteInfo', 'defaultGoodsImage', 'member'])
		},
		created() {
			this.keyword = this.$route.query.keyword || '';
			this.category_id = this.$route.query.category_id || '';
			this.level = this.$route.query.level || '';
			if (this.$route.name == 'street') this.searchType = 'shop';
			else this.searchType = 'goods';
			this.$store.dispatch('site/siteInfo');
			this.getCartList();
		},
		watch: {
			searchType() {
				this.searchTypeText = this.searchType == 'goods' ? '商品' : '店铺';
			},
			cartCount() {
				if (this.member) this.getCartList();
			},
			member() {
				if (!this.member) {
					this.$store.commit('cart/SET_CART_COUNT', 0);
					this.cartList = [];
					this.cartTotalPrice = 0;
				}
			},
			$route(curr) {
				if( curr.query.keyword != this.keyword ){
					this.keyword = curr.query.keyword;
				}
			},
		},
		methods: {
			handleCommand(command) {
				this.searchType = command;
			},
			search( string ) {
				if( string ){
					this.keyword = string.trim();
				}
				this.$router.push({
					path:"/list",
					query:{
						keyword:this.keyword
					}
				})
			},
			// 获取购物车数据
			getCartList() {
				apiCartList({})
					.then(res => {
						if (res.code >= 0 && res.data.length) {
							this.cartList = res.data;
							this.cartTotalPrice = 0
							this.cartList.forEach(item => {
								console.log(item.discount_price, '单价')
								let _cartTotalPrice = parseFloat(item.discount_price) * parseInt(item.num);
								this.cartTotalPrice += parseFloat(_cartTotalPrice)
								console.log(this.cartTotalPrice)
							});
							this.cartTotalPrice = this.cartTotalPrice.toFixed(2);
						}
					})
					.catch(res => {});
			},
			imageError(index) {
				this.cartList[index].sku_image = this.defaultGoodsImage;
			},
			// 删除单个购物车商品
			deleteCart(index) {
				this.$store
					.dispatch('cart/delete_cart', {
						cart_id: this.cartList[index].cart_id.toString()
					})
					.then(res => {
						if (res.code >= 0) {
							this.cartList.splice(index, 1);
							this.cartList.forEach(item => {
								let _cartTotalPrice = parseFloat(item.discount_price) * parseFloat(item.num);
								this.cartTotalPrice += parseFloat(_cartTotalPrice)
							});
							console.log(this.cartTotalPrice)
							this.cartTotalPrice = this.cartTotalPrice.toFixed(2);
							this.$forceUpdate();
						}
					})
					.catch(err => {});
			}
		}
	};
</script>

<style scoped lang="scss">
	.header-in {
		width: $width;
		height: 98px;
		margin: 20px auto 0;

		.logo-wrap {
			width: 240px;
			height: 86px;
			line-height: 86px;
			display: block;
			margin-left:20px;
			opacity: 0.8;
			transition: opacity .5s;
			img {
				max-width: 100%;
				max-height: 100%;
			}
			&:hover{
				opacity: 1;
			}
		}

		.in-sousuo {
			width: 620px;
			margin: 24px 0 0 100px;

			.sousuo-box {
				width: 100%;
				display: flex;
				input {
					color:#333;
					vertical-align: middle;
					line-height: 14px;
					width: 500px;
					height: 14px;
					padding:12px 10px;
					background: none;
					outline: none;
					border: 2px solid #ddd;
					border-right: none;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
					&:hover,&:focus {
						border-color:$base-color;
					}
				}

				button {
					margin:0;
					padding:0;
					border-radius: 0;
					background: $base-color;
					color:#fff;
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
					font-size: 18px;
					height:42px;
					width:100px;
					text-align: center;
				}
			}

			.hot-search-words {
				width: 100%;
				height: 20px;
				font-size: 12px;

				ul {
					overflow: hidden;
					margin: 0;
					height: 21px;
					padding: 0;
					color: #999;

					li {
						cursor: pointer;
						list-style: none;
						float: left;
						padding:5px 5px 0;

						&:hover {
							color: $base-color;
						}
					}
				}
			}
		}

		.cart-wrap {
			position: relative;
			float: right;

			.cart {
				margin-top: 26px;
				width: 100px;
				height: 42px;
				padding: 0 28px 0 19px;
				border: 1px solid #dfdfdf;
				color: $base-color;
				font-size: $ns-font-size-sm;
				display: block;
				position: absolute;
				right: 0;
				z-index: 101;

				span {
					cursor: pointer;
					line-height: 42px;
					margin-right: 10px;
				}
			}

			&:hover {
				.cart {
					border-bottom: 1px solid #ffffff;
				}

				.list {
					display: block;
				}
			}

			.list {
				position: absolute;
				top: 69px;
				right: 0;
				width: 340px;
				background: #fff;
				border: 1px solid #dfdfdf;
				display: none;
				z-index: 100;

				h4 {
					height: 25px;
					padding: 6px 8px;
					line-height: 25px;
				}

				.overflow-wrap {
					width: 340px;
					overflow: hidden;

					ul {
						max-height: 335px;

						&.overflow {
							overflow: auto;
							width: 354px;
						}

						li {
							background-color: #fff;
							display: block;
							font-size: 12px;
							padding: 8px 10px;
							position: relative;
							border-bottom: 1px solid #dfdfdf;
							overflow: hidden;

							.img-wrap {
								width: 50px;
								height: 50px;
								margin-right: 5px;
								overflow: hidden;
								float: left;
								text-align: center;
								line-height: 50px;
							}

							.goods-name {
								float: left;
								width: 140px;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
							}

							.operation {
								float: right;
								text-align: right;
								width: 90px;

								p {
									color: $base-color;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								span {
									cursor: pointer;

									&:hover {
										color: $base-color;
									}
								}
							}

							&:last-child {
								border-bottom: 0;
							}
						}
					}
				}

				.total {
					background-color: #fff;
					display: block;
					font-size: 12px;
					padding: 8px 10px;
					position: relative;
					border-bottom: 1px solid #dfdfdf;
					overflow: hidden;
					background-color: #f0f0f0;
					border-bottom: 0;
					left: 0;

					span {
						margin-top: 5px;
						display: inline-block;
						width: 70%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						strong {
							margin: 0 2px;
							color: $base-color;
							font-size: $ns-font-size-base;
						}
					}

					button {
						float: right;
					}
				}

				.empty {
					width: auto;
					height: 70px;
					line-height: 70px;
					text-align: center;
					color: #999;

					i {
						font-size: 28px;
					}

					span {
						display: inline-block;
						font-size: 12px;
						padding-right: 20px;
						margin-left: 10px;
					}
				}
			}
		}
	}
</style>
