<template>
	<div class="header">
		<ns-header-top />
		<ns-header-mid />

		<div class="nav">
			<div class="shop-sort" @mouseover="shopHover" @mouseleave="shopLeave">
				<router-link to="/category">商品分类</router-link>
				<i class="iconfont iconweibiaoti35"></i>
			</div>
			<div class="shop-list-content" :class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'">
	
				<!-- 分类类型3 -->
				<div class="shop-list" :class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'"
				 @mouseover="shopHover" @mouseleave="shopLeave">
					<div class="list-item" v-for="(item, index) in goodsCategoryTree" :key="index" @mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1"
					 >
						<router-link :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }" target="_blank"
						>
							<div class="list-item-left">
								<img class="category-img"
									v-if="categoryConfig.img == 1"
									:src="$util.img(item.image)" />
								<p class="category-name">{{item.category_name }}</p>
							</div>
							<i class="el-icon-arrow-right arrow" aria-hidden="true" v-if="item.child_list"></i> 
						</router-link>
						<div class="item-itm " :class="{'item-itm-img':categoryConfig.img == 1}">
							<router-link v-for="(second_item, second_index) in item.child_list" :key="second_index" :to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }" target="_blank"
							 v-show="second_index < 4"
							 style="display:inline-block;">
								{{ second_item.short_name?second_item.short_name : second_item.category_name }}
							</router-link>
						</div>
						<div class="cate-part" v-if="item.child_list" :class="{ show: selectedCategory == item.category_id }">
							<div class="cate-part-col1">
								<div style="display:flex">
									<div class="cate-detail">
										<dl class="cate-detail-item" style="border-bottom:1px solid #eee;width:400px;">
											<dt class="cate-detail-tit" style="font-weight:700;">
												<router-link target="_blank" :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }">
													{{ item.category_name }}
												</router-link>
											</dt>
										</dl>
										<div>
											<dl class="cate-detail-item" v-for="(second_item, second_index) in item.child_list" :key="second_index"
											>
												<dt class="cate-detail-tit">
													<router-link target="_blank" :to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }">
														{{ second_item.category_name }}
													</router-link>
												</dt>
											</dl>
										</div>
									</div>
									<div style="padding-top:60px">
										<router-link :to="item.link_url" v-if="item.image_adv">
											<el-image style="width:250px;height:400px;" :src="item.image_adv"></el-image>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			
			
			
			
			<!-- <nav>
				<ul>
					<li
						v-for="(nav_item, nav_index) in navList"
						:key="nav_index"
						:class="nav_item.url == navSelect ? 'router-link-active' : ''"
						@click="navUrl(nav_item.url, nav_item.is_blank)"
					>
						<span>{{ nav_item.nav_title }}</span>
					</li>
				</ul>
			</nav> -->
		</div>
	</div>
</template>

<script>
import NsHeaderTop from './NsHeaderTop';
import NsHeaderMid from './NsHeaderMid';
import { tree , categoryConfig} from '@/api/goods/goodscategory';
import { navList } from '@/api/website';
export default {
	props: {
		forceExpand: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isShopHover: false,
			isIndex: false,
			thisRoute: '',
			goodsCategoryTree: [],
			categoryConfig:{},
			selectedCategory: -1,
			navList: [],
			navSelect: '',
			isHide:false
		};
	},
	components: {
		NsHeaderTop,
		NsHeaderMid
	},
	computed: {},
	beforeCreate() {},
	created() {
		this.$store.dispatch('cart/cart_count');
		//this.getCategoryConfig();
		this.getTree();
		this.nav();
		if (this.$route.path == '/' || this.$route.path == '/index') this.isIndex = true;
	},
	mounted() {},
	watch: {
		$route: function(curr) {
			this.initNav(curr.path);
			if (curr.path == '/' || curr.path == '/index') this.isIndex = true;
			else this.isIndex = false;

			if (curr.path == '/list') {
				this.navSelect = '';
			}
		}
	},
	methods: {
		// 获取配置
		getCategoryConfig(){
			categoryConfig({
				
			})
			.then(res => {
				if (res.code == 0 && res.data) {
					this.categoryConfig = res.data;
					this.getTree(res.data);
				}
			})
			.catch(err => {
				this.$message.error(err.message);
			});
		},
		getTree(categoryConfig) {
			tree({
				level: 3,
				template: 2
			})
				.then(res => {
					if (res.code == 0 && res.data) {

						//重新格式化菜单树形结构：将卧室、客厅、餐厅、书房、儿童房放置第一层
						res.data = res.data || [];
						let tree = [];
						res.data.forEach( item=>{
							if( item.category_name == '家具' ){
								item.child_list.forEach( sub =>{
									tree.push( sub );
								} )
							}
							else{
								tree.push(item)
							}
						} )

						this.goodsCategoryTree = tree;
						
						for(let i =0;i<this.goodsCategoryTree.length;i++) {
							if(this.goodsCategoryTree[i].child_list > 3) {
								this.isHide = true
							}
						}
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		nav() {
			navList({})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.navList = res.data;
						for (let i in this.navList) {
							this.navList[i]['url'] = JSON.parse(this.navList[i]['nav_url']).url;
						}
						this.initNav(this.$route.path);
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		navUrl(url, target) {
			if (!url) return;
			if (url.indexOf('http') == -1) {
				if (target) this.$router.pushToTab({ path: url });
				else this.$router.push({ path: url });
			} else {
				if (target) window.open(url);
				else window.location.href = url;
			}
		},
		initNav(path) {
			for (let i in this.navList) {
				if (this.navList[i]['url'] == path) {
					this.navSelect = path;
					continue;
				}
			}
		},
		
		//鼠标移入显示商品分类
		shopHover() {
			this.isShopHover = true;
		},
		//鼠标移出商品分类隐藏
		shopLeave() {
			this.isShopHover = false;
		}
	}
};
</script>

<style scoped lang="scss">
.header {
	width: 100%;
	height: 201px;
	background-color: #fff;

	.shadow {
		box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
	}
	.border {
		border: 1px solid #f5f5f5;
	}

	.nav {
		width: $width;
		height: 41px;
		margin: 0 auto;
		position: relative;
		.shop-sort {
			width: 240px;
			height: 41px;
			text-align: center;
			color: #fff;
			background-color: $base-color;
			a {
				font-size: 16px;
				line-height: 41px;
				color: #fff;
				font-weight: 500;
			}
			i {
				font-size: 14px;
				float: right;
			}
		}
		.shop-list-content{
			width: 240px;
			height: 560px;
			position: absolute;
			left: 0;
			top: 41px;
			background-color: #333;
			display: none;
			padding: 0;
			box-sizing: border-box;
			font-size: $ns-font-size-base;
			z-index: 10;
			color:  #FFFFFF;
			box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.3);
			&::-webkit-scrollbar{
				display: none;
			}
			// overflow: hidden;
			.shop-list {
				width: 240px;
				height: 560px;
				overflow-y: auto;
				overflow-x: hidden;
				// box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
				&::-webkit-scrollbar{
					display: none;
				}
				a:hover {
					color: $base-color;
				}
				.list-item {
					padding-left: 12px;
					box-sizing: border-box;
					// min-height:50px;
					height: 80px;
					// line-height: 50px;
					padding:15px 15px 0 20px;
					a {
						display: flex;
						justify-content: space-between;
						align-items: center;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						// width: 95%;
						// display: block;
						color:  #FFFFFF;
						>div{
							display: flex;
							align-items: center;
						}
					}
					
					&:hover {
						background-color: #000;
						-webkit-transition: 0.2s ease-in-out;
						-moz-transition: -webkit-transform 0.2s ease-in-out;
						-moz-transition: 0.2s ease-in-out;
						transition: 0.2s ease-in-out;
						a:hover {
								color: $base-color;
						}
					}
					span:hover {
						color: $base-color;
					}
					.category-img{
						width:17px;
						height: 17px;
						margin-right: 10px;
					}
					.category-name{
						overflow:hidden;
						text-overflow:ellipsis;
						white-space:nowrap;
					}
					.item-itm {
						font-size: 14px;
						line-height: 16px; 
						height: 21px;
						overflow: hidden;
						
						a{
							margin-top: 5px;
							margin-right: 10px;
							height:15px;
							color:#BFBFBF;
							&:hover{
								color: $base-color !important;
							}
						}
						
						&.item-itm-img{
							margin-left: 27px;
						}
					}
					.cate-part {
						display: none;
						position: absolute;
						left: 240px;
						top: 0;
						z-index: auto;
						// width: 998px;
						width: 760px;
						height: 558px;
						overflow-y: auto;
						border: 1px solid #f7f7f7;
						background-color: #f9f9f9;
						box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.3);
						&.show {
							display: block;
						}
						&::-webkit-scrollbar{
							display: none;
						}
					}
					.cate-part-col1 {
						float: left;
						width: 100%;
						.cate-detail{
							width:400px;
							padding:10px 30px;
						}
						.cate-detail-item {
							position: relative;
							width:180px;
							display:inline-block;
							vertical-align:top;
							.cate-detail-tit {
								white-space: nowrap;
								text-overflow: ellipsis;
								a {
									display: block;
									padding-left:14px;
									height:48px;
									line-height:48px;
									color: #333333;
									border-radius: 4px;
								}
								a:hover{
									color: $base-color;
									background:#fff;
								}
								
							}
							.cate-detail-con {
								overflow: hidden;
								padding: 6px 0 6px 17px;
								display: flex;
								flex-wrap: wrap;
								// border-top: 1px dashed #eee;
								a {
									justify-content: start;
									font-size: 12px;
									height:30px;
								    /* float: left; */
								    margin: 4px 40px 4px 0;
								    padding: 0 10px;
								    white-space: nowrap;
								    line-height: 30px;
								    color: #666;
									width: calc((100% - 120px) / 4);
									display: flex;
									box-sizing: border-box;
									margin-top: 20px;
									.cate-detail-img{
										width:30px;
										height: 30px;
										margin-right: 10px;
									}
									&:nth-child(4n+4){
										margin-right: 0;
									}
								}
								a:hover{
									color: #FD274A;
								}
							}
							&:first-child .cate-detail-con {
								border-top: none;
							}
						}
					}
				}
			}
		}
		
		.shop-list-active {
			display: block;
		}
		nav {
			width: 934px;
			height: 36px;
			float: left;
			font-size: 14px;
			ul {
				margin: 0;
				padding: 0;
				width: 100%;
				height: 100%;
				li {
					cursor: pointer;
					list-style: none;
					float: left;
					padding: 8px 24px;
					a {
						&:hover {
							color: $base-color;
						}
					}
				}
				li:hover {
					color: $base-color;
					font-weight: bold;
				}
				.router-link-active {
					color: $base-color;
					font-weight: bold;
					border-bottom: 2px solid  #FD274A;
				}
			}
		}
	}
}
</style>
